/* TODO Add site wide styles */

* {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Oxygen-Sans, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', Helvetica, 'ヒラギノ角ゴ Pro W3',
		'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

button,
select {
	cursor: pointer;
}

button:disabled,
select:disabled {
	cursor: not-allowed;
}

.regular-button {
	border: 0px;
	height: 40px;
	display: inline-block;
	border-radius: 20px;
	-webkit-font-smoothing: antialiased;
	padding: 0px 18px;
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	vertical-align: middle;
	text-align: center;
	background-color: #efefef;
	color: #111111;
	/* width: 100%; */
}

.create-button {
	border: 0px;
	height: 40px;
	display: inline-block;
	border-radius: 20px;
	-webkit-font-smoothing: antialiased;
	padding: 0px 18px;
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	vertical-align: middle;
	text-align: center;
	background-color: rgb(230, 0, 35);
	color: rgb(255, 255, 255);
	/* width: 100%; */
}
.edit-errors {
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	color: red;
	font-style: italic;
	display: flex;
	justify-content: center;
}

.errors {
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	color: red;
	font-style: italic;
	display: flex;
	justify-content: center;
}
