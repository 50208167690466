.button-container {
	display: flex;
	justify-content: center;
	/* border: blue solid; */
	margin-top: 10px;
	padding: 10px;
	width: 100%;
}

.GridLayout--Container {
	padding: 0 60px;
	column-count: auto;
	column-width: 240px;
	column-fill: balance;
	gap: 1rem;
	align-content: center;
}

@media (min-width: 480px) {
	.GridLayout--Container {
		padding: 0 120px;
		column-width: 180px;
	}
}

@media (min-width: 768px) {
	.GridLayout--Container {
		padding: 0 80px;
		column-width: 240px;
	}
}

@media (min-width: 1024px) {
	.GridLayout--Container {
		padding: 0 120px;
	}
}

.GridLayout--Container .GridLayout--Item {
	display: inline-block;
	background: white;
	border-radius: 0.75rem;
	margin-bottom: 1rem;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.GridLayout--Item .GridLayout--Image {
	/* object-fit: cover; */
	width: 100%;
	min-height: 120px;
	/* border: 1px solid #aaa; */
	/* box-shadow: rgba(0,0,0,0.1) 2.5px 2.5px 2.5px; */
}

.GridLayout--Item .GridLayout--Actions {
	position: absolute;
	width: calc(100% - 20px);
	height: calc(100% - 20px);
	top: 0;
	left: 0;
	padding: 10px;
	color: white;
	opacity: 0;
}

.GridLayout--Item .GridLayout--Actions:hover {
	opacity: 1;
}

.GridLayout--Item .GridLayout--Action--Content {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
