/* .edit_profile_container{
    max-width: 12%;
    display:flex;
    flex-direction: column;
} */

/* .profile_header {
    max-width: 150px;

} */

.edit_profile_container {
	margin: 0;
	width: 100%;
	height: 100%;
	background: #ecf0f3;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	place-items: center;
}

.profile_header {
	position: relative;
	width: 350px;
	height: 100%;
	border-radius: 20px;
	padding: 40px;
	box-sizing: border-box;
	background: #ecf0f3;
	box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}

.edit-profile-form > h1 {
	font-size: 16pt;
}

.edit-profile-labels {
	display: flex;
	align-content: flex-end;
	justify-content: flex-end;
	flex-direction: column;
	text-align: left;
}

.edit-profile-inputs {
	padding: 10px;
	padding-left: 20px;
	height: 10px;
	font-size: 14px;
	border-radius: 20px;
	box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.edit-profile-input-about {
	padding: 10px;
	padding-left: 20px;
	height: auto;
	max-width: max-content;
	font-size: 14px;
	border-radius: 20px;
	box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}
