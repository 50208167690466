#navigation-bar {
	display: flex;
	align-items: center;
	height: 56px;
	margin: 10px 10px;
	gap: 20px;
}

.left_side {
	display: flex;
	flex: 1;
	height: 48px;
	align-items: center;
	justify-content: space-evenly;
	gap: 10px;
}

.search_middle {
	flex: 2;
	height: 48px;
	display: flex;
	border-radius: 50px;
	overflow: hidden;
	align-items: center;
	border: 1px solid grey;
	background-color: #efefef;
	color: black;
}

.hidden_search {
	pointer-events: none;
	opacity: 0;
}

.search_middle input {
	background-color: transparent;
	border: none;
	width: 100%;
	font-size: 16px;
	padding: 0 20px;
}

.search_middle input:focus {
	outline: none;
}

.search_middle:hover {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}

.right_side {
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: space-evenly;
	gap: 10px;
}

.right_side img {
	width: 25px;
}

.pinterest_icon img {
	width: 30px;
	height: 30px;
}

.dropdown_button {
	cursor: pointer;
	height: 100%;
}

.dropdown-menu {
	cursor: auto;
	top: 50px;
	right: 10px;
	position: fixed;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
	background-color: white;
	border-radius: 8px;
	padding: 10px 20px;
	width: 300px;
	margin-top: 20px;
	z-index: 10;
}

.dropdown-menu::before {
	content: '';
	top: -5px;
	height: 20px;
	width: 20px;
	background: rgb(53, 49, 49);
	transform: rotate(45deg);
}

.dropdown-menu.active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	transition: 500ms ease;
}

.dropdown-menu.inactive {
	opacity: 0;
	visibility: hidden;
	transform: translateY(-20px);
	transition: 500ms ease;
}

.dropdown-menu.active span {
	width: 24px;
	height: 1px;
	background: gray;
}

.menu_dropdown {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.menu_dropdown h5 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.menu_dropdown .create-button {
	align-self: flex-end;
}

.profile img {
	width: 40px;
}

.profile {
	display: flex;
	align-items: center;
}
.profile:hover {
	cursor: pointer;
	background-color: lightgrey;
}
.profile h5 {
	font-style: italic;
	margin: 0;
}

.user_info {
	display: flex;
	margin-left: 20px;
	justify-content: center;
	flex-direction: column;
}
