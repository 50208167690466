.create-pin-form {
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	place-items: center;
}

.create-form {
	position: relative;
	width: 350px;
	height: 100%;
	border-radius: 20px;
	padding: 40px;
	box-sizing: border-box;
	background: #ecf0f3;
	box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}

.create-form > h1 {
	font-size: 18pt;
}

.create-form > label {
	display: flex;
	align-content: flex-end;
	justify-content: flex-end;
	flex-direction: column;
	text-align: left;
}

.pin-input {
	margin-top: 5px;
	padding: 10px;
	padding-left: 20px;
	height: 10px;
	font-size: 14px;
	border-radius: 20px;
	box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}
