.modalBackground {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
}

.modalContainer {
	border-radius: 50px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	display: flex;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	flex-direction: column;
	padding: 25px;
}
.sign_up {
	display: flex;
	justify-content: center;
}

.modalContainer .title {
	display: inline-block;
	text-align: center;
	margin-top: 10px;
}

.titleCloseBtn {
	display: flex;
	justify-content: flex-end;
}

.titleCloseBtn button {
	background-color: transparent;
	border: none;
	font-size: 25px;
	cursor: pointer;
}

.modalContainer .body {
	flex: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.7rem;
	text-align: center;
}

.modalContainer .footer {
	flex: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modalContainer .footer button {
	width: 150px;
	height: 45px;
	margin: 10px;
	border: none;
	background-color: cornflowerblue;
	color: white;
	border-radius: 8px;
	font-size: 20px;
	cursor: pointer;
}

#cancelBtn {
	background-color: crimson;
}
