.following_container {
	z-index: 3;
	display: flex;
	align-items: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
}
.following_container img {
	width: 50px;
}
.following_info {
	margin-left: 20px;
}

.following_info h3 {
	margin-bottom: 0;
}

.following_info h4 {
	margin-top: 0;
}

.following_info:hover {
	color: red;
}
