/* .Splash {
	max-width: 1512px;
} */

.list-details {
	list-style: none;
}
.user-list-container {
	padding-left: 120px;
	/* padding-bottom: 20px; */
}

.follow-button-container {
	padding-left: 120px;
	padding-bottom: 20px;
}

.icon {
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: xx-large;
	color: rgb(230, 0, 35);
	padding-left: 20px;
	gap: 10px;
}

.icon img {
	width: 20px;
}

.nav_bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.right_menu {
	width: 340px;
	display: flex;
	justify-content: space-evenly;
	/* gap: 10px; */
	padding-right: 20px;
}

.body img {
	width: auto;
}
.openModalBtn {
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.login-button {
	border: 0px;
	height: 40px;
	display: inline-block;
	border-radius: 20px;
	-webkit-font-smoothing: antialiased;
	padding: 0px 18px;
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	margin-top: 8px;
	vertical-align: middle;
	text-align: center;
	background-color: rgb(230, 0, 35);
	color: rgb(255, 255, 255);
	width: 100%;
}
.signup-button {
	border: 0px;
	height: 40px;
	display: inline-block;
	border-radius: 20px;
	-webkit-font-smoothing: antialiased;
	padding: 0px 18px;
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	margin-top: 8px;
	vertical-align: middle;
	text-align: center;
	background-color: #efefef;
	color: #111111;
	width: 100%;
}
.submit-button {
	border: 0px;
	height: 40px;
	display: inline-block;
	border-radius: 20px;
	-webkit-font-smoothing: antialiased;
	padding: 0px 18px;
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	margin-top: 8px;
	vertical-align: middle;
	text-align: center;
	background-color: #efefef;
	color: #111111;
	/* width: 100%; */
}
