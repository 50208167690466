.BoardDetail--Editor--Container {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
}

.BoardDetail--Editor--Container .BoardDetail--Editor--Backdrop {
	background-color: rgba(0, 0, 0, 0.2);
	width: 100%;
	height: 100%;
}

.BoardDetail--Editor--Container .BoardDetail--Editor--Card {
	position: absolute;
	background: white;
	border: 1px solid #aaa;
	box-shadow: rgba(0, 0, 0, 0.1) 2.5px 2.5px 2.5px;
	border-radius: 20px;
	padding: 20px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 300px;
	display: flex;
	flex-direction: column;
}

.BoardDetail--Editor--Container
	.BoardDetail--Editor--Card
	.BoardDetail--Editor--Close {
	align-self: flex-end;
}

.BoardDetail--Editor--Container .BoardDetail--Editor--Card form {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.BoardDetail--Editor--Container .BoardDetail--Editor--Card button {
	align-self: flex-end;
}
