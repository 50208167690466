.MyBoards--Page {
	display: flex;
	flex-direction: column;
	gap: 40px;
}
.BoardDetail--Page {
	padding-bottom: 20px;
	/* border:blue solid; */
}

.MyBoards--Heading {
	display: flex;
	gap: 20px;
	justify-content: center;
	align-items: center;
}
